import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PnpDataComponent = () => {
    const [pnpData, setPnpData] = useState([]);
    const [lowData, setLowData] = useState([]); // Data for curr_mo_grs_submit < 3000 or 'N/A'
    const [tables, setTables] = useState([]);   // Data for the 5 tables
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchPnpData = async () => {
        try {
            // Fetch data from the updated API endpoint
            const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/pnp/sorted');  // Replace with your actual API URL
            const fetchedData = response.data.data;

            // Helper function to convert string value to a number
            const parseNumber = (value) => {
                if (!value || value === "N/A") return 0; // Convert 'N/A' to 0 for comparison
                return parseFloat(value.replace(/[^0-9.-]+/g, '')) || 0; // Strip non-numeric characters
            };

            // Separate the data into lowData (less than 3000 or N/A) and others (3000 or more)
            const highData = [];
            const lowDataArray = [];

            fetchedData.forEach(row => {
                const numericValue = parseNumber(row.curr_mo_grs_submit);
                if (numericValue < 3000) {
                    lowDataArray.push(row);
                } else {
                    highData.push({ ...row, curr_mo_grs_submit_num: numericValue });
                }
            });

            // Sort the high data in descending order
            highData.sort((a, b) => b.curr_mo_grs_submit_num - a.curr_mo_grs_submit_num);

            // Divide the high data into 5 equal tables
            const totalRows = highData.length;
            const rowsPerTable = Math.ceil(totalRows / 5);
            const tableGroups = [];

            for (let i = 0; i < 5; i++) {
                const start = i * rowsPerTable;
                const end = start + rowsPerTable;
                tableGroups.push(highData.slice(start, end));
            }

            // Set the state
            setPnpData(fetchedData); // Keeping the original full data if needed
            setLowData(lowDataArray);
            setTables(tableGroups);
            setLoading(false);
        } catch (err) {
            setError('Error fetching data');
            setLoading(false);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchPnpData();
    }, []);

    if (loading) return <p>Loading data...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h1>PNP Data Ranked by Curr Mo Grs Submit</h1>

            {/* Render 5 tables for curr_mo_grs_submit >= 3000 */}
            {tables.map((tableData, index) => (
                <div key={index}>
                    <h2>Table {index + 1}</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Name Line</th>
                                <th>ESID</th>
                                <th>Curr Mo Grs Submit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row) => (
                                <tr key={`${row.name_line}-${row.esid}`}>
                                    <td>{row.name_line}</td>
                                    <td>{row.esid}</td>
                                    <td>{row.curr_mo_grs_submit}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Render 1 table for curr_mo_grs_submit < 3000 or 'N/A' */}
            <h2>Table for Curr Mo Grs Submit &lt; 3000 or N/A</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name Line</th>
                        <th>ESID</th>
                        <th>Curr Mo Grs Submit</th>
                    </tr>
                </thead>
                <tbody>
                    {lowData.map((row) => (
                        <tr key={`${row.name_line}-${row.esid}`}>
                            <td>{row.name_line}</td>
                            <td>{row.esid}</td>
                            <td>{row.curr_mo_grs_submit}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PnpDataComponent;
