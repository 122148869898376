import React, { useState } from 'react';
import ClientInfo from './ClientInfo';
import ProposedInsureds from './InsuredInfo';
import MedicalQuestions from './MedicalQuestions';
import Premiums from './Premiums';

const VerificationForm = () => {
  const [saleType, setSaleType] = useState('No');
  const [annualPremium, setAnnualPremium] = useState(0);

  return (
    <div className="verification-form-container">
      <form action="https://script.google.com/macros/s/AKfycbxaMPFXULoOagfPR_-j-Pp2dprGS31ZTTSkwrU5PXZVAHZIlxfnlalR65N2dUU8Iut7/exec" method="POST">
        <ClientInfo />
        
     <ProposedInsureds />
        
        
        <div className="button-container">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default VerificationForm;
