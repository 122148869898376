import React from 'react';
import VerificationForm from './VerificationForm';

const Verify = () => {
    return (
        <div>
            <VerificationForm />

        </div>
    );
};

export default Verify;
