import React, { useState, useEffect, useRef } from 'react';

const ClientEmailInput = () => {
  const [emailHandle, setEmailHandle] = useState('');
  const [emailWebsite, setEmailWebsite] = useState('');
  const [emailDomain, setEmailDomain] = useState('com');
  const [hint, setHint] = useState('');

  const emailProviders = ['gmail', 'yahoo', 'outlook', 'hotmail', 'icloud', 'aol'];
  const domainExtensions = ['com', 'net', 'org', 'gov', 'edu'];

  // Reference to a hidden span to calculate the width of the input text
  const hiddenSpanRef = useRef(null);

  // Handle changes in email handle input
  const handleEmailHandleChange = (e) => {
    setEmailHandle(e.target.value);
  };

  // Handle changes in email website input
  const handleEmailWebsiteChange = (e) => {
    const value = e.target.value;
    setEmailWebsite(value);

    // Find the first matching suggestion for inline autocomplete
    const suggestion = emailProviders.find((provider) =>
      provider.toLowerCase().startsWith(value.toLowerCase())
    );
    setHint(suggestion ? suggestion : '');
  };

  // Handle domain extension selection
  const handleDomainChange = (e) => {
    setEmailDomain(e.target.value);
  };

  // Handle Tab press to accept the suggestion
  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && hint) {
      e.preventDefault(); // Prevent default Tab behavior
      setEmailWebsite(hint); // Set the email website to the hint value
      setHint(''); // Clear the hint
    }
  };

  // Function to calculate the width of the input text
  const getInputTextWidth = () => {
    if (hiddenSpanRef.current) {
      hiddenSpanRef.current.textContent = emailWebsite;
      return hiddenSpanRef.current.offsetWidth;
    }
    return 0;
  };

  return (
    <div className="input-group">
      <label htmlFor="client_email">Client Email on App</label>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        {/* Input for the email handle */}
        <input
          type="text"
          id="email_handle"
          name="email_handle"
          value={emailHandle}
          onChange={handleEmailHandleChange}
          placeholder="Enter email handle"
          required
          style={{ marginRight: '8px' }}
          autoComplete='off'
        />

        <span style={{ marginRight: '8px' }}>@</span>

        {/* Input for the email website with text hint */}
        <div style={{ position: 'relative', width: '150px' }}>
          <input
            type="text"
            id="email_website"
            name="email_website"
            value={emailWebsite}
            onChange={handleEmailWebsiteChange}
            onKeyDown={handleKeyDown}
            required
            autoComplete="off"
            style={{ marginRight: '8px', width: '100%' }}
          />
          
          {/* Text hint (displayed in a lighter color to simulate a placeholder) */}
          {hint && (
            <span
              style={{
                position: 'absolute',
                top: 3,
                left: `${getInputTextWidth() + 5}px`, // Position based on input text width
                color: '#ccc',
                pointerEvents: 'none',
              }}
            >
              {hint.substring(emailWebsite.length)}
            </span>
          )}
        </div>

        {/* Dropdown for email domain extensions */}
        <select
          id="email_domain"
          name="email_domain"
          value={emailDomain}
          onChange={handleDomainChange}
          required
        >
          {domainExtensions.map((extension, index) => (
            <option key={index} value={extension}>
              .{extension}
            </option>
          ))}
        </select>
      </div>

      {/* Hidden span used to calculate text width */}
      <span
        ref={hiddenSpanRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          whiteSpace: 'pre',
          fontSize: '16px', // Ensure the font size matches the input
        }}
      ></span>
    </div>
  );
};

export default ClientEmailInput;
