import React, { useState, useEffect } from 'react';

const TrainingView = () => {
  const [trainingData, setTrainingData] = useState([]);
  const [unclaimedData, setUnclaimedData] = useState([]);
  const [selectedUnclaimed, setSelectedUnclaimed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [unclaimedSearchTerm, setUnclaimedSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [unclaimedSortConfig, setUnclaimedSortConfig] = useState({ key: null, direction: 'asc' });
  const [unclaimedCollapsed, setUnclaimedCollapsed] = useState(true);

  useEffect(() => {
    fetchHierarchyAndTrainingData();
  }, []);

  const fetchHierarchyAndTrainingData = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const agnName = localStorage.getItem('agnName');

      if (!userId) {
        throw new Error('User ID not found in localStorage');
      }

      const hierarchyResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId }),
      });

      if (!hierarchyResponse.ok) {
        throw new Error('Failed to fetch hierarchy data');
      }

      const { success: hierarchySuccess, data: hierarchyData } = await hierarchyResponse.json();
      if (!hierarchySuccess) {
        throw new Error('Error retrieving hierarchy data');
      }

      const hierarchyEmails = hierarchyData.map(recruit => recruit.email);

      const trainingResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getprogress', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!trainingResponse.ok) {
        throw new Error('Failed to fetch training data');
      }

      const { data: trainingData } = await trainingResponse.json();

      const filteredTrainingData = trainingData.filter(recruit =>
        hierarchyEmails.includes(recruit.Email) || recruit.MGA === agnName
      );

      const unclaimed = trainingData.filter(recruit => !recruit.MGA);

      setTrainingData(filteredTrainingData);
      setUnclaimedData(unclaimed);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleUnclaimedSelect = (email) => {
    setSelectedUnclaimed(prev =>
      prev.includes(email) ? prev.filter(item => item !== email) : [...prev, email]
    );
  };

  const handleClaim = async () => {
    const agnName = localStorage.getItem('agnName');
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/claimMGA', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emails: selectedUnclaimed, agnName }),
      });

      if (!response.ok) {
        throw new Error('Failed to claim MGA');
      }

      fetchHierarchyAndTrainingData();
    } catch (error) {
      console.error('Error claiming MGA:', error.message);
    }
  };

  const handleSearch = (data, searchTerm) => {
    return data.filter(recruit => 
      recruit.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      recruit.Email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleSort = (data, sortConfig) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const toggleUnclaimedCollapse = () => {
    setUnclaimedCollapsed(!unclaimedCollapsed);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const userRole = localStorage.getItem('userRole');

  return (
    <div className="training-view">
      <h5>Training Progress</h5>
      
      {/* Search bar */}
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          display: 'block',
          margin: '20px auto',  
          padding: '5px',
          width: '50%',         
          border: '1px solid #ccc',
          borderRadius: '5px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          outline: 'none',
          fontSize: '14px',
        }}
      />
      
      <div className='hier-table-container'>
      <table className='maxbonus-table' >
      <thead>
            <tr>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'MGA', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>MGA</th>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'Name', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Name</th>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'ProductProgress', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Progress</th>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'LastActivityAt', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Last Login</th>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'Logins', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Logins</th>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'StartDate', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Start Date</th>
              <th className='top-table-header' onClick={() => setSortConfig({ key: 'Email', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Email</th>
            </tr>
          </thead>
          <tbody>
            {handleSort(handleSearch(trainingData, searchTerm), sortConfig).length === 0 ? (
              <tr>
                <td colSpan="7">No matching training data available</td>
              </tr>
            ) : (
              handleSort(handleSearch(trainingData, searchTerm), sortConfig).map((recruit, index) => (
                <tr key={index}>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.MGA || 'N/A'}</td>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.Name || 'N/A'}</td>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.ProductProgress !== null ? `${recruit.ProductProgress}%` : 'N/A'}</td>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.LastActivityAt || 'N/A'}</td>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.Logins || 'N/A'}</td>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.StartDate || 'N/A'}</td>
                  <td className='table-input-like' style={{whiteSpace: 'nowrap'}}>{recruit.Email || 'N/A'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Collapsible Unclaimed Section */}
      {(userRole === 'MGA' || userRole === 'RGA') && (
        <>
          <h5 onClick={toggleUnclaimedCollapse} style={{ cursor: 'pointer' }}>
            {unclaimedCollapsed ? 'Show' : 'Hide'} Unclaimed Trainees
          </h5>
<p>Email kvanbibber@ariasagencies.com to claim a trainee from this table.</p>
          {!unclaimedCollapsed && (
            <>
              <input
                type="text"
                placeholder="Search"
                value={unclaimedSearchTerm}
                onChange={(e) => setUnclaimedSearchTerm(e.target.value)}
                style={{
                  display: 'block',
                  margin: '20px auto',  
                  padding: '5px',
                  width: '50%',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  outline: 'none',
                  fontSize: '14px',
                }}
              />
              <div style={{ overflowX: 'auto' }}>
                <table className='maxbonus-table'>
                  <thead>
                    <tr>
                      <th className='top-table-header' onClick={() => setUnclaimedSortConfig({ key: 'Name', direction: unclaimedSortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Name</th>
                      <th className='top-table-header' onClick={() => setUnclaimedSortConfig({ key: 'Email', direction: unclaimedSortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Email</th>
                      <th className='top-table-header' onClick={() => setUnclaimedSortConfig({ key: 'ProductProgress', direction: unclaimedSortConfig.direction === 'asc' ? 'desc' : 'asc' })}>Progress</th>
                    </tr>
                  </thead>
                  <tbody>
                    {handleSort(handleSearch(unclaimedData, unclaimedSearchTerm), unclaimedSortConfig).length === 0 ? (
                      <tr>
                        <td colSpan="4">No unclaimed recruits available</td>
                      </tr>
                    ) : (
                      handleSort(handleSearch(unclaimedData, unclaimedSearchTerm), unclaimedSortConfig).map((recruit, index) => (
                        <tr key={index}>
                          <td className='table-input-like'>{recruit.Name || 'N/A'}</td>
                          <td className='table-input-like'>{recruit.Email || 'N/A'}</td>
                          <td className='table-input-like'>{recruit.ProductProgress !== null ? `${recruit.ProductProgress}%` : 'N/A'}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {selectedUnclaimed.length > 0 && (
                <button onClick={handleClaim}>Claim Selected</button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TrainingView;
