import React, { useState } from 'react';
import './MedQuestions.css'; // Import the same CSS file for styling

const SeniorMedicalQuestions = ({ insureds }) => {
  const [answers, setAnswers] = useState({
    senior_rejected: '',
    heart_lung: '',
    cirrhosis: '',
    amputation: '',
    cancer_senior: '',
    oxygen: '',
  });

  const [checkedInsureds, setCheckedInsureds] = useState({
    senior_rejected: [],
    heart_lung: [],
    cirrhosis: [],
    amputation: [],
    cancer_senior: [],
    oxygen: [],
  });

  const handleRadioChange = (e, question) => {
    const value = e.target.value;
    setAnswers((prev) => ({
      ...prev,
      [question]: value,
    }));

    if (value === 'No') {
      setCheckedInsureds((prev) => ({
        ...prev,
        [question]: [],
      }));
    }
  };

  const handleCheckboxChange = (e, question, insured) => {
    const isChecked = e.target.checked;
    setCheckedInsureds((prev) => {
      if (isChecked) {
        return {
          ...prev,
          [question]: [...prev[question], insured],
        };
      } else {
        return {
          ...prev,
          [question]: prev[question].filter((name) => name !== insured),
        };
      }
    });
  };

  const selectAllNo = () => {
    setAnswers((prev) => {
      const updatedAnswers = { ...prev };
      ['senior_rejected', 'heart_lung', 'cirrhosis', 'amputation', 'cancer_senior', 'oxygen'].forEach((question) => {
        updatedAnswers[question] = 'No';
      });
      return updatedAnswers;
    });

    setCheckedInsureds((prev) => {
      const updatedChecked = { ...prev };
      ['senior_rejected', 'heart_lung', 'cirrhosis', 'amputation', 'cancer_senior', 'oxygen'].forEach((question) => {
        updatedChecked[question] = [];
      });
      return updatedChecked;
    });
  };

  const questions = [
    { label: 'Has any proposed insured ever been <strong>rejected for insurance by American Income Life</strong>?', key: 'senior_rejected' },
    { label: 'Has any proposed insured been advised to have or had a <strong>heart</strong>, lung, liver or bone marrow transplant?', key: 'heart_lung' },
    { label: 'Has any proposed insured been diagnosed or treated for <strong>cirrhosis, Alzheimer\'s disease, ALS or senile dementia</strong>?', key: 'cirrhosis' },
    { label: 'Has any proposed insured had a <strong>toe, foot, or leg amputated</strong> due to illness or disease?', key: 'amputation' },
    { label: 'In the past 2 years, has the proposed insured been <strong>hospitalized for heart attack or stroke</strong> or been diagnosed or received treatment for <strong>cancer</strong>?', key: 'cancer_senior' },
    { label: 'Is the proposed insured <strong>bedridden, hospitalized, or require oxygen equipment</strong> to assist in breathing?', key: 'oxygen' }
  ];

  const renderQuestion = (label, questionKey) => (
    <div className="question-group">
      <div className="question-row">
        <span className="question-label" dangerouslySetInnerHTML={{ __html: label }}></span>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name={questionKey}
              value="Yes"
              required
              checked={answers[questionKey] === 'Yes'}
              onChange={(e) => handleRadioChange(e, questionKey)}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name={questionKey}
              value="No"
              required
              checked={answers[questionKey] === 'No'}
              onChange={(e) => handleRadioChange(e, questionKey)}
            />
            No
          </label>
        </div>
      </div>

      {answers[questionKey] === 'Yes' && insureds.length > 0 && (
        <ul id={`${questionKey}_list`} className="checkbox-list">
          {insureds.map((insured, index) => (
            <li key={index} className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={checkedInsureds[questionKey].includes(insured)}
                  onChange={(e) => handleCheckboxChange(e, questionKey, insured)}
                />
                {insured.name}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div id="seniorMedicalQuestions" className="transition-section">
      <h2>
        Senior Medical Questions
        <div className="no-to-all-container">
          <button type="button" onClick={selectAllNo}>No to All</button>
        </div>
      </h2>
      <div className="questions_section">
        {questions.map((q) => renderQuestion(q.label, q.key))}
      </div>
    </div>
  );
};

export default SeniorMedicalQuestions;
