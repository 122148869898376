import React, { useState, useEffect } from 'react';
import MedicalQuestions from './MedicalQuestions'; // Import Medical Questions component
import SeniorMedicalQuestions from './SeniorMedicalQuestions'; // Import Senior Medical Questions component
import './VerificationForm.css';
const InsuredInfo = () => {
  const [spouseAdded, setSpouseAdded] = useState(false);
  const [children, setChildren] = useState([]); // Tracks number of children
  const [childrenInfo, setChildrenInfo] = useState([]); // Tracks children information
  const [premiums, setPremiums] = useState({
    primary: 0,
    spouse: 0,
    children: [],
  });
  const [totalMonthlyPremium, setTotalMonthlyPremium] = useState(0);
  const [trialMonthlyPremium, setTrialMonthlyPremium] = useState(0); // For trial premiums
  const [totalAnnualPremium, setTotalAnnualPremium] = useState(0);

  // State for insured names and senior/trial status
  const [insureds, setInsureds] = useState({
    primary: { firstName: '', lastName: '', trial: false, senior: false },
    spouse: { firstName: '', lastName: '', trial: false, senior: false },
    children: [],
  });

  // Add spouse
  const addSpouse = () => {
    if (!spouseAdded) {
      setSpouseAdded(true);
    }
  };

  // Add child
  const addChild = () => {
    if (children.length < 9) {
      setChildren([...children, children.length + 1]);
      setChildrenInfo([...childrenInfo, { firstName: '', lastName: '' }]);
      setInsureds((prev) => ({
        ...prev,
        children: [...prev.children, { firstName: '', lastName: '', trial: false, senior: false }],
      }));
    }
  };

  // Remove spouse and reset spouse-related data
  const removeSpouse = () => {
    setSpouseAdded(false);
    setPremiums((prev) => ({
      ...prev,
      spouse: 0, // Reset spouse premium to 0
    }));
    setInsureds((prev) => ({
      ...prev,
      spouse: { firstName: '', lastName: '', trial: false, senior: false },
    }));
  };

  // Remove child and shift data
  const removeChild = (index) => {
    const updatedChildren = children.filter((_, i) => i !== index);
    const updatedChildrenInfo = childrenInfo.filter((_, i) => i !== index);
    const updatedInsuredChildren = insureds.children.filter((_, i) => i !== index);

    setChildren(updatedChildren);
    setChildrenInfo(updatedChildrenInfo);
    setInsureds((prev) => ({
      ...prev,
      children: updatedInsuredChildren,
    }));
  };

  // Handle premium input changes
  const handlePremiumChange = (e, type, index = null) => {
    const value = parseFloat(e.target.value) || 0;
    setPremiums((prev) => {
      if (type === 'primary') {
        return {
          ...prev,
          primary: value,
        };
      } else if (type === 'spouse') {
        return {
          ...prev,
          spouse: value,
        };
      } else if (type === 'child') {
        const updatedChildren = [...premiums.children];
        updatedChildren[index] = value;
        return {
          ...prev,
          children: updatedChildren,
        };
      }
      return prev;
    });
  };

  // Handle changes in children inputs
  const handleChildChange = (e, index, field) => {
    const value = e.target.value;
    const updatedChildrenInfo = [...childrenInfo];
    updatedChildrenInfo[index][field] = value;
    setChildrenInfo(updatedChildrenInfo);
    setInsureds((prev) => {
      const updatedInsuredChildren = [...prev.children];
      updatedInsuredChildren[index][field] = value;
      return { ...prev, children: updatedInsuredChildren };
    });
  };

  // Handle primary and spouse name changes
  const handlePrimaryChange = (e, field) => {
    const value = e.target.value;
    setInsureds((prev) => ({
      ...prev,
      primary: { ...prev.primary, [field]: value },
    }));
  };

  const handleSpouseChange = (e, field) => {
    const value = e.target.value;
    setInsureds((prev) => ({
      ...prev,
      spouse: { ...prev.spouse, [field]: value },
    }));
  };

  // Toggle trial mode
  const toggleTrial = (type, index = null) => {
    if (type === 'primary') {
      setInsureds((prev) => ({
        ...prev,
        primary: { ...prev.primary, trial: !prev.primary.trial },
      }));
    } else if (type === 'spouse') {
      setInsureds((prev) => ({
        ...prev,
        spouse: { ...prev.spouse, trial: !prev.spouse.trial },
      }));
    } else if (type === 'child') {
      setInsureds((prev) => {
        const updatedChildren = [...prev.children];
        updatedChildren[index].trial = !updatedChildren[index].trial;
        return { ...prev, children: updatedChildren };
      });
    }
  };

  // Toggle senior status
  const toggleSenior = (type, index = null) => {
    if (type === 'primary') {
      setInsureds((prev) => ({
        ...prev,
        primary: { ...prev.primary, senior: !prev.primary.senior },
      }));
    } else if (type === 'spouse') {
      setInsureds((prev) => ({
        ...prev,
        spouse: { ...prev.spouse, senior: !prev.spouse.senior },
      }));
    } else if (type === 'child') {
      setInsureds((prev) => {
        const updatedChildren = [...prev.children];
        updatedChildren[index].senior = !updatedChildren[index].senior;
        return { ...prev, children: updatedChildren };
      });
    }
  };

  // Calculate the total monthly premium (excluding trials), trial premiums, and total annual premium
  useEffect(() => {
    const primaryMBD = insureds.primary.trial ? 0 : premiums.primary;
    const spouseMBD = insureds.spouse.trial ? 0 : premiums.spouse;
    const childrenMBD = premiums.children.reduce(
      (total, mbd, index) => total + (insureds.children[index]?.trial ? 0 : mbd),
      0
    );

    const trialPrimary = insureds.primary.trial ? premiums.primary : 0;
    const trialSpouse = insureds.spouse.trial ? premiums.spouse : 0;
    const trialChildren = premiums.children.reduce(
      (total, mbd, index) => total + (insureds.children[index]?.trial ? mbd : 0),
      0
    );

    const monthly = primaryMBD + spouseMBD + childrenMBD;
    const trialMonthly = trialPrimary + trialSpouse + trialChildren;

    setTotalMonthlyPremium(monthly);
    setTrialMonthlyPremium(trialMonthly);
    setTotalAnnualPremium(monthly * 12);
  }, [premiums, insureds]);

  // Determine if medical questions and/or senior medical questions should be shown
  const shouldShowMedicalQuestions = totalAnnualPremium >= 1800;
  const shouldShowSeniorMedicalQuestions = insureds.primary.senior || (spouseAdded && insureds.spouse.senior);

  // Determine which question sets to display
  const questionSet = (() => {
    if (insureds.primary.senior && !spouseAdded && children.length === 0) {
      return 'senior'; // Show only senior medical questions when only primary is senior
    } else if (spouseAdded && insureds.primary.senior && insureds.spouse.senior && children.length === 0) {
      return 'senior'; // Show only senior medical questions when both primary and spouse are seniors and no children
    } else if (spouseAdded && insureds.primary.senior && insureds.spouse.senior && children.length > 0) {
      return 'both'; // Show both medical and senior questions when both are seniors and children are present
    } else if (insureds.primary.senior || insureds.spouse.senior) {
      return 'both'; // Show both medical and senior questions if either is senior
    } else {
      return 'medical'; // Show only medical questions when neither primary nor spouse is senior
    }
  })();

  return (
    <>
    <hr />
      <h4>Proposed Insured Info</h4>
      <div className="insured-button-container">
  {!spouseAdded && <button type="button" className='insured-button' onClick={addSpouse}>Add Spouse</button>}
  {spouseAdded && <button type="button" className='insured-button' onClick={removeSpouse}>Remove Spouse</button>}
  {children.length < 9 && <button type="button" className='insured-button' onClick={addChild}>Add Child</button>}
</div>

      {/* Primary Insured */}
      <div className="insured-group">
  <div className="insured-label-checkboxes">
    <label className="insured-label">Primary Insured</label>
    <div className="checkbox-group">
      <label>
        <input type="checkbox" checked={insureds.primary.trial} onChange={() => toggleTrial('primary')} />
        Trial
      </label>
      <label>
        <input type="checkbox" checked={insureds.primary.senior} onChange={() => toggleSenior('primary')} />
        Senior
      </label>
    </div>
  </div>
  <div className="primary-insured input-group">
    <input
      type="text"
      id="primary_insured_first"
      name="primary_insured_first"
      placeholder="First Name"
      onChange={(e) => handlePrimaryChange(e, 'firstName')}
      required
      className="modern-input"
      autoComplete="off"
    />
    <input
      type="text"
      id="primary_insured_last"
      name="primary_insured_last"
      placeholder="Last Name"
      onChange={(e) => handlePrimaryChange(e, 'lastName')}
      required
      className="modern-input"
      autoComplete="off"
    />
    <input
      type="number"
      className="premium-input"
      id="primary_insured_premium"
      name="primary_insured_premium"
      placeholder="Primary Insured MBD"
      step="0.01"
      onChange={(e) => handlePremiumChange(e, 'primary')}
    />
  </div>
</div>


      {/* Spouse Insured */}
      {spouseAdded && (
  <div className="insured-group">
    <div className="insured-label-checkboxes">
      <label className="insured-label">Spouse</label>
      <div className="checkbox-group">
        <label>
          <input type="checkbox" checked={insureds.spouse.trial} onChange={() => toggleTrial('spouse')} />
          Trial
        </label>
        <label>
          <input type="checkbox" checked={insureds.spouse.senior} onChange={() => toggleSenior('spouse')} />
          Senior
        </label>
      </div>
    </div>
    <div className="spouse-insured input-group">
      <input
        type="text"
        id="spouse_first"
        name="spouse_first"
        placeholder="Spouse First Name"
        onChange={(e) => handleSpouseChange(e, 'firstName')}
        required
        className="modern-input"
        autoComplete="off"
      />
      <input
        type="text"
        id="spouse_last"
        name="spouse_last"
        placeholder="Spouse Last Name"
        onChange={(e) => handleSpouseChange(e, 'lastName')}
        required
        className="modern-input"
        autoComplete="off"
      />
      <input
        type="number"
        className="premium-input"
        id="spouse_premium"
        name="spouse_premium"
        placeholder="Spouse MBD"
        step="0.01"
        onChange={(e) => handlePremiumChange(e, 'spouse')}
      />
    </div>
  </div>
)}


      {/* Children Insured */}
      <div className="children-insured">
  {children.map((_, index) => (
    <div key={index} className="insured-group">
      <label className="insured-label">{`Child ${index + 1}`}</label>
      <div>
              <label>
                <input type="checkbox" checked={insureds.children[index]?.trial || false} onChange={() => toggleTrial('child', index)} />
                Trial
              </label>
            </div>
      <div className="child-insured input-group">
            <input
              type="text"
              id={`child_first_${index}`}
              name={`child_first_${index}`}
              placeholder="Child First Name"
              value={childrenInfo[index]?.firstName || ''}
              onChange={(e) => handleChildChange(e, index, 'firstName')}
              required
              className='modern-input'
              autoComplete='off'
            />
            <input
              type="text"
              id={`child_last_${index}`}
              name={`child_last_${index}`}
              placeholder="Child Last Name"
              value={childrenInfo[index]?.lastName || ''}
              onChange={(e) => handleChildChange(e, index, 'lastName')}
              required
              className='modern-input'
              autoComplete='off'
            />
            <input
              type="number"
              className="premium-input"
              id={`child_premium_${index}`}
              name={`child_premium_${index}`}
              placeholder="Child MBD"
              step="0.01"
              value={premiums.children[index] || ''}
              onChange={(e) => handlePremiumChange(e, 'child', index)}
            />

            <button type="button" onClick={() => removeChild(index)}>Remove</button>
          </div>
          </div>  
        ))}
      </div>
<hr />
{/* Premium Information */}
<div className="premiums-container">
  <div className="premium-row">
    <div className="premium-item">
      <label>Total Monthly Premium</label>
      <input type="text" id="monthly_premium" name="monthly_premium" value={totalMonthlyPremium.toFixed(2)} readOnly />
    </div>
    <div className="premium-item">
      <label>Trial Monthly Premium</label>
      <input type="text" id="trial_monthly_premium" name="trial_monthly_premium" value={trialMonthlyPremium.toFixed(2)} readOnly />
    </div>
    <div className="premium-item">
      <label>Total Annual Premium</label>
      <input type="text" id="annual_premium" name="annual_premium" value={totalAnnualPremium.toFixed(2)} readOnly />
    </div>
  </div>
</div>

      {totalAnnualPremium >= 1800 && questionSet === 'medical' && shouldShowMedicalQuestions && (
        <MedicalQuestions
          insureds={insureds.children
            .filter(child => !child.senior)
            .concat(
              !insureds.primary.senior
                ? [{ name: `${insureds.primary.firstName} ${insureds.primary.lastName}`, isSenior: insureds.primary.senior }]
                : []
            )
            .concat(
              spouseAdded && !insureds.spouse.senior
                ? [{ name: `${insureds.spouse.firstName} ${insureds.spouse.lastName}`, isSenior: insureds.spouse.senior }]
                : []
            )}
        />
      )}

      {totalAnnualPremium >= 1800 && questionSet === 'senior' && (
        <SeniorMedicalQuestions
          insureds={insureds.children
            .filter(child => child.senior)
            .concat(
              insureds.primary.senior
                ? [{ name: `${insureds.primary.firstName} ${insureds.primary.lastName}`, isSenior: insureds.primary.senior }]
                : []
            )
            .concat(
              spouseAdded && insureds.spouse.senior
                ? [{ name: `${insureds.spouse.firstName} ${insureds.spouse.lastName}`, isSenior: insureds.spouse.senior }]
                : []
            )}
        />
      )}

      {totalAnnualPremium >= 1800 && questionSet === 'both' && (
        <>
          <SeniorMedicalQuestions
            insureds={insureds.children
              .filter(child => child.senior)
              .concat(
                insureds.primary.senior
                  ? [{ name: `${insureds.primary.firstName} ${insureds.primary.lastName}`, isSenior: insureds.primary.senior }]
                  : []
              )
              .concat(
                spouseAdded && insureds.spouse.senior
                  ? [{ name: `${insureds.spouse.firstName} ${insureds.spouse.lastName}`, isSenior: insureds.spouse.senior }]
                  : []
              )}
          />
          <MedicalQuestions
            insureds={insureds.children
              .filter(child => !child.senior)
              .concat(
                !insureds.primary.senior
                  ? [{ name: `${insureds.primary.firstName} ${insureds.primary.lastName}`, isSenior: insureds.primary.senior }]
                  : []
              )
              .concat(
                spouseAdded && !insureds.spouse.senior
                  ? [{ name: `${insureds.spouse.firstName} ${insureds.spouse.lastName}`, isSenior: insureds.spouse.senior }]
                  : []
              )}
          />
        </>
      )}
    </>
  );
};

export default InsuredInfo;
