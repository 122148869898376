import React, { useState, useEffect, useRef } from 'react';
import ClientEmailInput from './ClientEmailInput'; // Import the ClientEmailInput component

const ClientInfo = () => {
  const [agentName, setAgentName] = useState('');
  const [agentEmail, setAgentEmail] = useState('');
  const [agentInfoLoaded, setAgentInfoLoaded] = useState(false); // To track if agent info has been loaded
  const [allAgents, setAllAgents] = useState([]); // Store all agents data for dropdown
  const [selectedAgent, setSelectedAgent] = useState(''); // Store selected agent from dropdown
  const userRole = localStorage.getItem('userRole'); // Get the user role from localStorage

  const areaCodeRef = useRef(null); // Ref for area code input
  const prefixRef = useRef(null); // Ref for prefix input
  const lineNumberRef = useRef(null); // Ref for line number input

  const [phoneNumber, setPhoneNumber] = useState({
    areaCode: '',
    prefix: '',
    lineNumber: ''
  });

  // Fetch agent information from the API (using POST method)
  const fetchAgentInfo = async (userId) => {
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/searchByUserId', {
        method: 'POST', // Use POST request
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }), // Send userId in the request body
      });
      const data = await response.json();

      if (data.success && data.data && data.data.length > 0) {
        const agentData = data.data; // All agents data
        const defaultAgent = data.agnName; // Default agent name (agnName)

        // Set agent name and email for the default agent
        setAgentName(defaultAgent);
        const defaultEmail = agentData.find(agent => agent.lagnname === defaultAgent)?.email || '';
        setAgentEmail(defaultEmail);

        // Set all agents data for the dropdown
        setAllAgents(agentData);
        setSelectedAgent(defaultAgent); // Set default selected agent
      } else {
        console.error('Agent info not found or invalid data format');
      }
      setAgentInfoLoaded(true); // Mark as loaded after the fetch
    } catch (error) {
      console.error('Error fetching agent info:', error);
      setAgentInfoLoaded(true); // Mark as loaded even on error
    }
  };

  // useEffect to fetch agent info on page load
  useEffect(() => {
    const userId = localStorage.getItem('userId'); // Get userId from localStorage
    if (userId) {
      fetchAgentInfo(userId); // Fetch agent info on page load
    } else {
      console.error('User ID not found in localStorage');
      setAgentInfoLoaded(true); // Mark as loaded even if userId is missing
    }
  }, []); // Empty dependency array ensures this runs only on component mount

  // Handle agent change from dropdown
  const handleAgentChange = (e) => {
    const selected = e.target.value;
    setSelectedAgent(selected);

    // Update agent email based on the selected agent
    const selectedAgentEmail = allAgents.find(agent => agent.lagnname === selected)?.email || '';
    setAgentEmail(selectedAgentEmail);
  };

  // Function to handle input and move focus
  const handlePhoneNumberChange = (e, field, nextRef, prevRef) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only numbers
    if (value.length <= e.target.maxLength) {
      setPhoneNumber(prev => ({ ...prev, [field]: value }));
    }

    // Jump to the next input if the current input is filled
    if (value.length === e.target.maxLength && nextRef) {
      nextRef.current.focus();
    }

    // Handle backspace to move to previous input
    if (e.key === 'Backspace' && value.length === 0 && prevRef) {
      prevRef.current.focus();
    }
  };

  return (
    <div className="client-info-group">
      <h4>Client Information</h4>
      <div className="input-group">
        <label htmlFor="client_name">Name on Application Package</label>
        <input type="text" id="client_name" name="client_name" autoComplete="off" required />
      </div>
      <div className="input-group">
        <label htmlFor="phone-number">Client Phone Number:</label>
        <div className="phone-number-container">
          <input
            type="text"
            id="area-code"
            ref={areaCodeRef}
            maxLength="3"
            required
            autoComplete="off"
            value={phoneNumber.areaCode}
            onChange={(e) => handlePhoneNumberChange(e, 'areaCode', prefixRef)}
            onKeyDown={(e) => handlePhoneNumberChange(e, 'areaCode', prefixRef)}
          />
          <input
            type="text"
            id="prefix"
            ref={prefixRef}
            maxLength="3"
            required
            autoComplete="off"
            value={phoneNumber.prefix}
            onChange={(e) => handlePhoneNumberChange(e, 'prefix', lineNumberRef, areaCodeRef)}
            onKeyDown={(e) => handlePhoneNumberChange(e, 'prefix', lineNumberRef, areaCodeRef)}
          />
          <input
            type="text"
            id="line-number"
            ref={lineNumberRef}
            maxLength="4"
            required
            autoComplete="off"
            value={phoneNumber.lineNumber}
            onChange={(e) => handlePhoneNumberChange(e, 'lineNumber', null, prefixRef)}
            onKeyDown={(e) => handlePhoneNumberChange(e, 'lineNumber', null, prefixRef)}
          />
        </div>
      </div>

      {/* Use ClientEmailInput component here */}
      <ClientEmailInput />

      {agentInfoLoaded && (
        <div className="agent-info-group">
    <hr />
    <h4>Agent Information</h4>

    {userRole === 'AGT' ? (
      <>
        <div className="input-row"> {/* Added a wrapper for the row */}
          <div className="input-group">
            <label htmlFor="agent_name">Agent Name</label>
            <input type="text" id="agent_name" name="agent_name" value={agentName} readOnly />
          </div>
          <div className="input-group">
            <label htmlFor="agent_email">Agent Email</label>
            <input type="email" id="agent_email" name="agent_email" value={agentEmail} readOnly />
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="input-row"> {/* Added a wrapper for the row */}
          <div className="input-group">
            <label htmlFor="agent_name">Agent Name</label>
            <select id="agent_name" value={selectedAgent} onChange={handleAgentChange}>
              {allAgents.map((agent, index) => (
                <option key={index} value={agent.lagnname}>
                  {agent.lagnname}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="agent_email">Agent Email</label>
            <input type="email" id="agent_email" name="agent_email" value={agentEmail} readOnly />
          </div>
        </div>
      </>
    )}
  </div>
)}


      {!agentInfoLoaded && <p>Loading agent information...</p>}
    </div>
  );
};

export default ClientInfo;
