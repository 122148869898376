import React, { useState } from 'react';
import './MedQuestions.css'; // Import the CSS file for styling

const MedicalQuestions = ({ insureds }) => {
  const [answers, setAnswers] = useState({
    dui: '',
    arrested: '',
    heart_issues: '',
    high_blood_pressure: '',
    diabetes: '',
    anxiety_depression: '',
    cancer: '',
    medications: '',
    er_visit: '',
    chronic_illness: '',
  });

  const [checkedInsureds, setCheckedInsureds] = useState({
    dui: [],
    arrested: [],
    heart_issues: [],
    high_blood_pressure: [],
    diabetes: [],
    anxiety_depression: [],
    cancer: [],
    medications: [],
    er_visit: [],
    chronic_illness: [],
  });

  const handleRadioChange = (e, question) => {
    const value = e.target.value;
    setAnswers((prev) => ({
      ...prev,
      [question]: value,
    }));

    if (value === 'No') {
      setCheckedInsureds((prev) => ({
        ...prev,
        [question]: [],
      }));
    }
  };

  const handleCheckboxChange = (e, question, insured) => {
    const isChecked = e.target.checked;
    setCheckedInsureds((prev) => {
      if (isChecked) {
        return {
          ...prev,
          [question]: [...prev[question], insured],
        };
      } else {
        return {
          ...prev,
          [question]: prev[question].filter((name) => name !== insured),
        };
      }
    });
  };

  const selectAllNo = () => {
    setAnswers((prev) => {
      const updatedAnswers = { ...prev };
      Object.keys(answers).forEach((question) => {
        updatedAnswers[question] = 'No';
      });
      return updatedAnswers;
    });

    setCheckedInsureds((prev) => {
      const updatedChecked = { ...prev };
      Object.keys(checkedInsureds).forEach((question) => {
        updatedChecked[question] = [];
      });
      return updatedChecked;
    });
  };

  const questions = [
    { label: 'Has any proposed insured ever been convicted of a <strong>DWI</strong> or <strong>DUI</strong>?', key: 'dui' },
    { label: 'Has any applicant ever been <strong>arrested</strong>?', key: 'arrested' },
    { label: 'Has any proposed insured ever had or been treated for <strong>Angioplasty</strong>, <strong>Coronary Bypass</strong>, <strong>Heart Attack</strong>, <strong>Heart Failure</strong>, <strong>Angina</strong>, or <strong>Artery Disease</strong>?', key: 'heart_issues' },
    { label: 'Has any proposed insured ever been diagnosed as having or received treatment for <strong>high blood pressure</strong>?', key: 'high_blood_pressure' },
    { label: 'Has any proposed insured ever had or been treated for <strong>Diabetes</strong>?', key: 'diabetes' },
    { label: 'Has any proposed insured taken medications for <strong>Anxiety</strong> or <strong>Depression</strong>?', key: 'anxiety_depression' },
    { label: 'Has any proposed insured ever been diagnosed as having or received treatment for <strong>cancer, tumor or unexplained masses</strong>?', key: 'cancer' },
    { label: 'Are you currently taking or have you been prescribed <strong>medications by a doctor in the past five years</strong>?', key: 'medications' },
    { label: 'In the past 5 years, has any proposed insured been to the <strong>ER</strong> or <strong>Hospital</strong>?', key: 'er_visit' },
    { label: 'Has any proposed insured ever been diagnosed with or treated for <strong>Crohn\'s</strong>, <strong>Cystic Fibrosis</strong>, <strong>COPD</strong>, <strong>Seizures</strong>, <strong>Scleroderma</strong>, <strong>Alzheimer\'s</strong>, <strong>Multiple Sclerosis</strong>, <strong>Stroke</strong>, <strong>Sickle Cell Anemia</strong>, <strong>liver</strong>, or <strong>kidney issues</strong>?', key: 'chronic_illness' }
  ];

  const renderQuestion = (label, questionKey) => (
    <div className="question-group">
      <div className="question-row">
        <span className="question-label" dangerouslySetInnerHTML={{ __html: label }}></span>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name={questionKey}
              value="Yes"
              required
              checked={answers[questionKey] === 'Yes'}
              onChange={(e) => handleRadioChange(e, questionKey)}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name={questionKey}
              value="No"
              required
              checked={answers[questionKey] === 'No'}
              onChange={(e) => handleRadioChange(e, questionKey)}
            />
            No
          </label>
        </div>
      </div>

      {answers[questionKey] === 'Yes' && insureds.length > 0 && (
        <ul className="checkbox-list">
          {insureds.map((insured, index) => (
            <li key={index} className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={checkedInsureds[questionKey].includes(insured)}
                  onChange={(e) => handleCheckboxChange(e, questionKey, insured)}
                />
                {insured.name}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div id="medicalQuestions" className="transition-section">
      <h2>
        Medical Questions
        <div className="no-to-all-container">
          <button type="button" onClick={selectAllNo}>No to All</button>
        </div>
      </h2>
      <div className="questions_section">
        {questions.map((q) => renderQuestion(q.label, q.key))}
      </div>
    </div>
  );
};

export default MedicalQuestions;
